<template>
  <!-- 关注公众号版块 -->
  <div class="follow">
    <h3>ءۇنحات تەكشەگە نازار سالىڭىز</h3>
    <div class="info">
      <img class="icon" src="https://mmbiz.qpic.cn/mmbiz_jpg/D5gYF9aqevlOOibaNzC4uOelv9629k6bDMWjCGT3c8AUeibzd9SJOy57eU13xYFiaMpsMjMiaCO0P3c3jYMytFfxBg/640?wx_fmt=jpeg&amp;from=appmsg" />
      <div>
        <p>مايرا ساباقحاناسى</p>
        <p>ءبىلىم بىلگەن سايىن قىزىق!</p>
      </div>
      <span @click="showPopup">نازار سالۋ</span>
    </div>
    <van-popup v-model="show">
      <div class="qrCode">
        <img
          src="https://mmbiz.qpic.cn/mmbiz_jpg/D5gYF9aqevlOOibaNzC4uOelv9629k6bDMWjCGT3c8AUeibzd9SJOy57eU13xYFiaMpsMjMiaCO0P3c3jYMytFfxBg/640?wx_fmt=jpeg&amp;from=appmsg"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'Follow',
  data () {
    return {
      show: false
    }
  },
  methods: {
    showPopup () {
      this.show = true
    }
  }
}
</script>

<style lang="less" scoped>
img {
  pointer-events: auto; /* 禁止长按图片保存 */
}
.follow {
  background-color: #fff;
  padding: 20px;

  h3 {
    text-align: right;
    direction: rtl;
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .info {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 20px 10px;

    .icon {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-left: 14px;
      flex-shrink: 0;
    }

    > div {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      text-align: right;
      direction: rtl;
      line-height: 40px;

      p {
        &:first-of-type {
          font-size: 28px;
          color: #333;
        }
        &:last-of-type {
          font-size: 24px;
          color: #999;
        }
      }
    }

    span {
      font-size: 26px;
      color: #fff;
      background-color: #2a75ed;
      border-radius: 30px;
      padding: 10px 16px;
    }
  }

  .qrCode {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    img {
      width: 600px;
    }
  }
}

/deep/.van-popup {
  border-radius: 10px;
}
</style>
